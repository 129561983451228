<template>
  <div>
    <LoadingPage v-if="loading" />

    <b-card v-else>
      <b-tabs
        v-model="tabIndex"
        pills
        lazy
      >
        <!-- Tab: Account -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <BLink
              :to="{}"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 0 ? 'active-title' : ''"
              >Account</span>
            </BLink>
          </template>

          <UserViewTabAccount
            :user="userData"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab:Change Password -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <BLink
              :to="{ query: { tab: 'change-password' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 1 ? 'active-title' : ''"
              >Change Password</span>
            </BLink>
          </template>

          <UserViewTabChangePassword class="mt-2 pt-75" />
        </b-tab>
        <!-- Tab:Rebuy Token List -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="ClockIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <BLink
              :to="{ query: { tab: 'rebuy-token-list' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 2 ? 'active-title' : ''"
              >Rebuy Token</span>
            </BLink>
          </template>

          <UserViewTabReBuyToken class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Transaction History -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="RepeatIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <BLink
              :to="{ query: { tab: 'transaction' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 3 ? 'active-title' : ''"
              >Transaction</span>
            </BLink>
          </template>

          <UserViewTabTransaction
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Bonus Bet -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="PlusIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <BLink
              :to="{ query: { tab: 'bonus-bet' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 4 ? 'active-title' : ''"
              >Bonus Bet</span>
            </BLink>
          </template>

          <UserViewTabBonusBet
            :user="userData"
            class="mt-2 pt-75"
          />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LoadingPage from '@core/components/LoadingPage'
import {
  BCard, BLink, BTab, BTabs,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import UserViewTabAccount from './UserViewTabAccount'
// import UserViewTabAdminHistory from './UserViewTabAdminHistory.vue'
// import UserViewTabUserHistory from './UserViewTabUserHistory .vue'
import UserViewTabTransaction from './UserViewTabTransaction.vue'
import UserViewTabReBuyToken from './UserViewTabReBuyToken.vue'
import UserViewTabChangePassword from './UserViewTabChangePassword.vue'
import UserViewTabBonusBet from './UserViewTabBonusBet.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    UserViewTabAccount,
    // UserViewTabAdminHistory,
    LoadingPage,
    // UserViewTabUserHistory,
    // UserViewTabTrans,
    UserViewTabTransaction,
    BLink,
    UserViewTabReBuyToken,
    UserViewTabChangePassword,
    UserViewTabBonusBet,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
    }
  },
  computed: {
    ...mapState({
      userData: s => s.user.detail.userData,
      stateAddress: s => s.user.newUserParams.state,
    }),

  },

  mounted() {
    switch (this.$route.query.tab) {
      // case 'transaction-history':
      //   this.tabIndex = 1
      //   break
      // case 'deposit-history':
      //   this.tabIndex = 2
      //   break
      // case 'exclude-history':
      //   this.tabIndex = 3
      //   break
      // case 'account-history':
      //   this.tabIndex = 4
      //   break
      // case 'rebuy-history':
      //   this.tabIndex = 5
      //   break
      case 'change-password':
        this.tabIndex = 1
        break
      case 'rebuy-token-list':
        this.tabIndex = 2
        break
      default:
        break
    }
  },
  async created() {
    this.loading = true
    await this.syncDetail()
    this.loading = false
  },
  methods: {
    ...mapActions({
      fetchUser: 'user/detail/asyncUser',
      getNewUserParamsAsync: 'user/newUserParams/getNewUserParamsAsync',
    }),
    async syncDetail() {
      await this.getNewUserParamsAsync()
      await this.fetchUser({ id: this.$router.currentRoute.params.id })
    },
  },
}
</script>

<style lang="scss" scoped>
.active-title {
  color: white;
}
</style>
