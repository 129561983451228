<template>
  <section>
    <b-modal
      ref="add-user-offer"
      :title="!offerEdit || !offerEdit.name ? 'Add Offer' : 'Edit offer'"
      centered
      hide-footer
      cancel-variant="outline-secondary"
      size="md"
      @hide="hideModal"
      @ok="handleSubmitModal"
    >
      <section style="color: black">
        <!-- OFFER NAME -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Offer name</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <b-form-input v-model="offerName" type="text" placeholder="Add name..." />
          </b-col>
        </b-row>
        <small v-if="errorName" class="text-danger">{{ errorName }}</small>

        <!-- DESCRIPTION -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Description</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column">
            <b-form-input v-model="description" type="text" placeholder="Add description..." />
          </b-col>
        </b-row>
        <small v-if="errorDescription" class="text-danger">{{ errorDescription }}</small>

        <!-- BONUS PERCENT -->
        <b-row v-if="!offerEdit || !offerEdit.name" class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Bonus percent</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input v-model="bonusPercent" placeholder="50%" type="number" :no-wheel="true" />
          </b-col>
        </b-row>
        <small v-if="errorBonusPercent" class="text-danger">{{ errorBonusPercent }}</small>

        <!-- BONUS LIMIT -->
        <b-row v-if="!offerEdit || !offerEdit.name" class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Bonus limit</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input
              v-model="bonusLimit"
              type="number"
              placeholder="$50"
              :no-wheel="true"
              :formatter="formatPrice"
            />
          </b-col>
        </b-row>
        <small v-if="errorBonusLimit" class="text-danger">{{ errorBonusLimit }}</small>

        <!-- OPEN AT -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Open at</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <flat-pickr
              v-model="openDate"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:s',
                enableSeconds: true,
                static: true,
              }"
            />
          </b-col>
        </b-row>
        <small v-if="errorOpenDate" class="text-danger">{{ errorOpenDate }}</small>

        <!-- CLOSE AT -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Close at</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <flat-pickr
              id="closeDate"
              v-model="closeDate"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:s',
                enableSeconds: true,
                static: true,
              }"
            />
          </b-col>
        </b-row>
        <small v-if="errorCloseDate" class="text-danger">{{ errorCloseDate }}</small>

        <!-- EXPIRED DAYS -->
        <b-row class="mt-2">
          <b-col cols="3" class="d-flex align-items-center">
            <div class="font-weight-bold">Bonus expired days</div>
          </b-col>
          <b-col cols="8" class="d-flex" style="flex-direction: column; gap: 12px">
            <b-form-input v-model="depositExpiredDays" type="number" :no-wheel="true" />
          </b-col>
        </b-row>
        <small v-if="errorExpiredDay" class="text-danger">{{ errorExpiredDay }}</small>

        <b-row>
          <b-col class="d-flex justify-content-end mt-4">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loadingCreateBonus"
              @click="handleSubmitModal"
            >
              <Spinner v-if="loadingCreateBonus" />
              <template v-else>{{ !offerEdit || !offerEdit.name ? 'Create Offer' : 'Save' }}  </template>
            </b-button>
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </section>
</template>

<script>
import {
  BBadge,
  BCol,
  BRow,
  BButton,
  BCard,
  BFormInput,
  BDropdown,
  BFormRadioGroup,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { formatDateQuery, formatCurrencyBuiltInFunc, formatDate, formatDateSend } from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import vSelect from 'vue-select'
import useToastHandler from '@/services/toastHandler'
import flatPickr from 'vue-flatpickr-component'
import { subDays } from 'date-fns'
import { booleanProp, objectProp } from '@/helpers/props'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BButton,
    Spinner,
    vSelect,
    flatPickr,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BPagination,
  },
  props: {
    isShowModal: booleanProp(),
    offerEdit: objectProp(),
  },
  data() {
    return {
      listProfile: { id: 1, value: '' },
      selectedProfileList: [],

      offerName: '',
      description: '',
      bonusLimit: null,
      customerProfile: null,
      openDate: new Date(),
      closeDate: new Date(),
      depositExpiredDays: null,
      bonusPercent: null,

      errorName: '',
      errorDescription: '',
      errorBonusPercent: '',
      errorBonusLimit: '',
      errorChooseProfile: '',
      errorOpenDate: '',
      errorCloseDate: '',
      errorExpiredDay: '',
      loadingAddCampaign: false,
      type: '',
      couponCampaignId: null,
      deleteId: null,

      disableDate: subDays(new Date(), 1),
    }
  },
  computed: {
    ...mapState('races/bonusCash', ['loadingCreateBonus', 'listUserByOffer', 'totalUser', 'bonusId']),
  },
  watch: {
    offerName(val) {
      if (val) this.errorName = ''
    },
    description(val) {
      if (val) this.errorDescription = ''
    },
    bonusPercent(val) {
      if (val) this.errorBonusPercent = ''
    },
    bonusLimit(val) {
      if (val) this.errorBonusLimit = ''
    },
    depositExpiredDays(val) {
      if (val) this.errorExpiredDay = ''
    },
    isShowModal(val) {
      if (val) this.$refs['add-user-offer'].show()
    },
    offerEdit(val) {
      if(!val.name ) return
      this.offerName = val.name
      this.description = val.description
      this.openDate = new Date(val.openAt)
      this.closeDate = new Date(val.closeAt)
      this.depositExpiredDays = val.bonusExpiredDays
    },
  },

  methods: {
    ...mapActions({
      createBonus: 'races/bonusCash/createBonus',
      getBonusOfferUser: 'account/bonusCash/getBonusOfferUser',
      updateOffer: 'races/bonusCash/updateOffer',
    }),
    formatPrice(value) {
      if (value < 0) return null
      return value
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      if (!this.offerName) this.errorName = 'Campaign name is required'
      if (!this.description) this.errorDescription = 'Description is required'
      if (!this.bonusLimit && !this.offerEdit?.name) this.errorBonusLimit = 'Bonus limit is required'
      if (!this.bonusPercent && !this.offerEdit?.name) this.errorBonusPercent = 'Bonus percent is required'
      if (!this.depositExpiredDays) this.errorExpiredDay = 'Expired day is required'
      if (
        this.errorName ||
        this.errorDescription ||
        this.errorBonusLimit ||
        this.errorBonusPercent ||
        this.errorExpiredDay
      )
        return
      // edit offer
      if (this.offerEdit?.name) {
        const res = await this.updateOffer({
          bonusOfferId: this.offerEdit.bonusOfferId,
          data: {
            name: this.offerName,
            description: this.description,
            type: 'deposit',
            openAt: this.formatDateSend(this.openDate),
            closeAt: this.formatDateSend(this.closeDate),
            bonusExpiredDays: Number(this.depositExpiredDays),
            data: {
              percent: this.offerEdit.data.percent,
              bonusLimit: this.offerEdit.data.bonusLimit,
            },
          },
        })
        if (res.data) {
          this.showToastSuccess('Success', 'Update offer successfully!')
        }
        this.$refs['add-user-offer'].hide()
        this.$emit('hide-show-modal')
        this.resetModal()
        return
      }

      // create new offer
      const data = {
        name: this.offerName,
        description: this.description,
        type: 'deposit',
        openAt: this.formatDateSend(this.openDate),
        closeAt: this.formatDateSend(this.closeDate),
        bonusExpiredDays: Number(this.depositExpiredDays),
        data: {
          percent: this.bonusPercent,
          bonusLimit: this.bonusLimit,
        },
        applies: [
          {
            appliableType: 'UserId',
            appliableId: this.$router.currentRoute.params.id,
          },
        ],
      }


      const res = await this.createBonus(data)
      if (res.data) {
        await this.getBonusOfferUser({
          page: 1,
          limit: 100,
          userId: this.$router.currentRoute.params.id,
        })
        this.showToastSuccess('Success', 'Create offer successfully!')
      }
      this.$refs['add-user-offer'].hide()
      this.$emit('hide-show-modal')
      this.resetModal()
    },
    hideModal() {
      this.resetModal()
      this.$emit('update-data')
      this.$refs['add-user-offer'].hide()
    },
    resetModal() {
      this.offerName = ''
      this.depositExpiredDays = null
      this.customerProfile = null
      this.bonusLimit = null
      this.bonusPercent = null
      this.description = null
      this.openDate = new Date()
      this.closeDate = new Date()
      this.bonusExpiredDays = null
      this.errorName = ''
      this.errorDescription = ''
      this.errorExpiredDay = ''
      this.errorBonusLimit = ''
      this.errorBonusPercent = ''
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()
    return {
      showToastSuccess,
      showToastError,
      formatDateQuery,
      formatCurrencyBuiltInFunc,
      formatDate,
      formatDateSend,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-set {
  height: fit-content;
}
.add-btn {
  padding: 2px 4px;
}

.remove-btn {
  padding: 0px 4px 2px 4px;
  margin-left: 1px;
}

.delete-btn {
  color: red;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.v-select .vs__dropdown-toggle,
.v-select .vs__search {
  cursor: pointer;
}
@import '@core/scss/vue/libs/vue-select.scss';

.ml-14 {
  margin-left: 14px;
}
.dropdown-item.disabled {
  background: rgb(190, 189, 189);
}
</style>
