<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="mb-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>rows</label>
          </b-col>
          <b-col
            cols="12"
            md="7"
            class="mb-2"
          >
            <div class="d-flex flex-md-row flex-column align-items-center justify-content-end">
              <b-col
                cols="12"
                md="6"
                class="mb-md-0 mb-2"
              >
                <label>Status</label>
                <v-select
                  v-model="filterStatus"
                  :clearable="false"
                  :options="statusOptions"
                  class="w-100"
                />
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="mb-md-0 mb-2"
              >
                <label>Expire</label>
                <v-select
                  v-model="filterExpire"
                  :clearable="false"
                  :options="expireOptions"
                  class="w-100"
                />
              </b-col>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refReBuyTokenListTable"
        :items="handleGetListToken"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="tokenList.length === 0"
            class="text-center text-white my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: couponId -->
        <template #cell(couponId)="{ item }">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ item.couponId }}</span>
          </div>
        </template>
        <!-- Column: couponCode -->
        <template #cell(couponCode)="{ item }">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ item.couponCode }}</span>
          </div>
        </template>
        <!-- Column: expiredTime -->
        <template #cell(expiredTime)="{ item }">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatEventDate(item.expiredTime) }}</span>
          </div>
        </template>
        <!-- Column: used -->
        <template #cell(used)="{ item }">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ item.used }}</span>
          </div>
        </template>
        <!-- Column: createdTime -->
        <template #cell(createdTime)="{ item }">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDateShort(item.createdTime) }}</span>
          </div>
        </template>
        <!-- Column: createdTime -->
        <template #cell(createdBy)="{ item }">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ item.createdBy }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalReBuyToken"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import {
  formatDateShort, formatPrice, formatBetBottom, formatBetTop,
} from '@core/utils/filter'
import useToastHandler from '@/services/toastHandler'
import { formatEventDate } from '@/helpers/utils'
import useReBuyTokenList from './useReBuyTokenList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,

    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      filterStatus: 'All',
      filterExpire: 'All',
    }
  },
  computed: {
    ...mapState({
      transaction: s => s.user.transaction,
    }),
    ...mapState('user/detail', ['totalToken', 'tokenList', 'loadingListToken']),
  },
  watch: {
    filterStatus() {
      this.refReBuyTokenListTable.refresh()
    },
    filterExpire() {
      this.refReBuyTokenListTable.refresh()
    },
  },
  methods: {
    ...mapActions({
      getListReBuy: 'user/detail/getListReBuy',
    }),
    formatStatus(val) {
      switch (val) {
        case 'All':
          return 'all'
        case 'Used':
          return 'used'
        case 'Not Use':
          return 'unused'
        default:
          return ''
      }
    },

    async handleGetListToken() {
      const res = await this.getListReBuy({
        id: this.$router.currentRoute.params.id,
        limit: this.perPage,
        page: this.currentPage,
        ...(this.filterExpire !== 'All' && { shouldExpired: this.filterExpire === 'Expired' ? 1 : 0 }),
        couponStatus: this.formatStatus(this.filterStatus),
        couponType: 'REBUY_TOKEN',
      })

      if (!res) {
        this.showToastError('Error', 'Fail To Load List Re-buy Token')
      }
      this.totalReBuyToken = res.pagination.total
      return res.coupons
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalReBuyToken,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      refReBuyTokenListTable,
      sortBy,

      // UI

      resolveTransactionStatusName,
      resolveTransactionStatusVariant,

      // Extra Filters
      tnxIDFilter,

      statusOptions,
      expireOptions,
    } = useReBuyTokenList()

    return {
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalReBuyToken,
      dataMeta,
      perPageOptions,
      searchQuery,
      refReBuyTokenListTable,
      isSortDirDesc,
      sortBy,

      // UI
      resolveTransactionStatusName,
      resolveTransactionStatusVariant,

      // Extra Filters
      tnxIDFilter,

      showToastError,
      showToastSuccess,

      formatDateShort,
      formatPrice,
      formatBetBottom,
      formatBetTop,
      formatEventDate,

      statusOptions,
      expireOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.text-wrap {
  width: 380px;
  line-height: 22px;
}
</style>
