<template>
  <div>
    <!-- Input Fields -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- Field: Password -->
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label="New Password"
              label-for="new-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:6|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="newPassword"
                    :type="passwordTypeNew"
                    name="new-password"
                    placeholder="New Password"
                    autocomplete="new-password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Confirm Password -->
          <b-col
            cols="12"
            md="6"
            xl="6"
          >
            <b-form-group
              label="Confirm Password"
              label-for="confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="confirmPassword"
                    :type="passwordConfirm"
                    name="confirm-password"
                    placeholder="Confirm Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconConfirm"
                      class="cursor-pointer"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Action Buttons -->
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loading"
              @click="handleChangePassword"
            >
              <Spinner v-if="loading" />
              <template v-else>
                Save changes
              </template>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Spinner from '@core/components/Spinner'
import { mapState, mapActions } from 'vuex'
import { required, min, confirmed } from '@validations'
import useToastHandler from '@/services/toastHandler'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    Spinner,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      passwordTypeNew: 'password',
      passwordConfirm: 'password',
      required,
      min,
      confirmed,
    }
  },
  computed: {
    ...mapState('account/changePassword', ['loading', 'error']),

    passwordToggleIconNew() {
      return this.passwordTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      changePasswordAsync: 'account/changePassword/changePasswordAsync',
    }),
    async handleChangePassword() {
      const valid = await this.$refs.simpleRules.validate()
      if (!valid) return

      const result = await this.changePasswordAsync({
        userId: this.$router.currentRoute.params.id,
        password: this.newPassword,
      })

      if (!result) return
      this.showToastSuccess('Successs', 'Password changed successfully')
      this.resetForm()
    },

    togglePasswordNew() {
      this.passwordTypeNew = this.passwordTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordConfirm() {
      this.passwordConfirm = this.passwordConfirm === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.newPassword = ''
      this.confirmPassword = ''
      this.$refs.simpleRules.reset()
    },
  },
  setup() {
    const { showToastSuccess } = useToastHandler()

    return { showToastSuccess }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
