<template>
  <div>
    <validation-observer ref="generalUserForm">
      <b-container class="bv-example-row">
        <!-- USER DETAIL -->
        <b-row>
          <b-col cols="12">
            <div class="d-flex justify-content-end general-col">
              <p>FullName: {{ fullName }}</p>
            </div>
            <div class="d-flex justify-content-end general-col">
              <p>Bonus Cash Amount: {{ formatCurrencyBuiltInFunc(bonusCashOfUser | 0) }}</p>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <p style="font-weight: bold; font-size: 16px">
              Issue New Bonus Cash Offer
            </p>
            <div style="display: flex; align-items: center;">
              <p style="font-weight: bold; font-size: 16px; margin-top: 6px">
                Add
              </p>
              <b-button
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                class="ml-2"
                @click="handleAddNewOffer"
              >
                CREATE NEW OFFER
              </b-button>

            </div>
          </b-col>
          <b-col cols="12">
            <b-row style="align-items: center; margin-top: 10px">
              <b-col cols="3">
                Issue Bonus Cash Offer:
              </b-col>
              <b-col
                cols="9"
                class="d-flex"
              >
                <!-- <b-button
                  variant="primary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="handleShowListOffer"
                >
                  {{ selectedOfferDeposit ? `${selectedOfferDeposit.bonusOfferId}. ${selectedOfferDeposit.name}` : 'USE EXISTING OFFER' }}
                </b-button> -->
                <b-dropdown
                  id="dropdown"
                  :text="selectedOfferDeposit ? `${selectedOfferDeposit.id}. ${selectedOfferDeposit.name} - ${selectedOfferDeposit.percent}%` : 'USE EXISTING OFFER'"
                  variant="primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in listOfferDefault"
                    :key="index"
                    @click="handleSelectOfferDepositDefault(item)"
                  >{{ `${item.id}. ${item.name} - ${item.percent}%` }}</b-dropdown-item>
                </b-dropdown>
                <b-button
                  variant="primary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="ml-2"
                  :disabled="!selectedOfferDeposit"
                  @click="handleApplyOfferForUser"
                >
                  <template v-if="loadingCreateBonus">
                    <Spinner variant="light" />
                  </template>
                  <template v-else>
                    APPLY
                  </template>

                </b-button>

                <!-- <b-button
                  variant="primary"
                  class="mb-2 mb-sm-0"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="handleAddNewOffer"
                >
                  <template> Create new offer </template>
                </b-button> -->
              </b-col>
            </b-row>
            <small
              v-if="errorBonusOffer"
              class="text-danger"
            >{{ errorBonusOffer }}</small>
            <b-row style="align-items: center; margin-top: 12px;">
              <b-col
                cols="3"
                style="display: flex; flex-direction: column;"
              >
                Issue Bonus Cash Direct:

                <small
                  v-if="defaultOfferDirect"
                  style="font-weight: bold;"
                >Default cash offer: {{ defaultOfferDirect.bonusOfferId }}</small>
                <b-button
                  variant="primary"
                  style="padding: 4px; width: 100px; font-size: 10px; margin-left: 20px;"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="handleShowListOfferDirect"
                >
                  <template> CHANGE </template>
                </b-button>
              </b-col>
              <b-col
                cols="9"
                class="d-flex"
              >
                <b-form-input
                  v-model="bonusCash"
                  type="number"
                  placeholder="Enter amount"
                  :no-wheel="true"
                  :formatter="formatPrice"
                  style="width: 150px; margin-right: 10px;"
                />
                <b-form-input
                  v-model="bonusCashExpireDirect"
                  type="number"
                  placeholder="Enter days to expire"
                  :no-wheel="true"
                  :formatter="formatPrice"
                  style="width: 200px; "
                />
                <b-button
                  variant="primary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="ml-2"
                  :disabled=" !bonusCash || !bonusCashExpireDirect"
                  @click="handleApplyBonusForUser"
                >
                  <template>
                    Apply
                  </template>
                </b-button>
              </b-col>

            </b-row>
            <small
              v-if="errorBonusAmount"
              class="text-danger"
            >{{ errorBonusAmount }}</small>
          </b-col>

          <AddOfferForUser
            :is-show-modal="isShowModal"
            :offer-edit="offerEdit"
            @update-data="resetShowModalCreateOffer"
          />
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <p style="font-weight: bold; font-size: 16px; margin-top: 6px">
              Deduct
            </p>
          </b-col>

          <b-col cols="7">
            <b-row style="align-items: center; margin-top: 10px">
              <b-col cols="7">
                Issue Bonus Cash Offer:
              </b-col>
              <b-col cols="5">
                <b-dropdown
                  variant="link"
                  no-caret
                  boundary="viewport"
                  :disabled="listBonusDeduct.length < 1"
                >
                  <template #button-content>
                    <div style="display: flex; gap: 30px">
                      <div
                        v-if="offerSelectedDeduct"
                        style="
                          width: 106px;
                          overflow: hidden;
                          text-overflow: ellipsis !important;
                          white-space: nowrap;
                          text-align: left;
                        "
                      >
                        {{ `${offerSelectedDeduct.id}` }}
                      </div>

                      <b-button
                        variant="primary"
                        class="mb-1 mb-sm-0"
                        style="padding: 2px 4px"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      >
                        <template> + </template>
                      </b-button>
                    </div>
                  </template>
                  <template>
                    <b-dropdown-item
                      v-for="(i, ind) in listBonusDeduct"
                      :key="ind"
                      style="text-align: left"
                      @click="handleSelectBonusDeduct(i)"
                    >
                      {{ `Bonus Id: ${i.id}` }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </b-col>
            </b-row>

            <b-row
              v-if="offerSelectedDeduct"
              style="align-items: center"
            >
              <b-col
                cols="7"
                style="display: flex; flex-direction: column"
              >
                Amount:
                <small style="font-weight: bold;">Deductible amount: {{ formatCurrencyBuiltInFunc(offerSelectedDeduct.remain) }}</small>
              </b-col>
              <b-col cols="5">
                <b-form-input
                  v-model="bonusDeduct"
                  type="number"
                  placeholder="$50"
                  :no-wheel="true"
                  :formatter="formatPriceDeduct"
                />
              </b-col>
            </b-row>
            <small
              v-if="errorBonusDeductAmount"
              class="text-danger"
            >{{ errorBonusDeductAmount }}</small>
          </b-col>
          <b-col
            v-if="offerSelectedDeduct"
            cols="5"
            style="display: flex; align-items: center; margin-top: 10px"
          >
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              class="ml-4"
              @click="handleApplyDeduct"
            >
              <Spinner v-if="loading && offerSelectedDeduct" />
              <template v-else>
                Apply
              </template>
            </b-button>
          </b-col>
        </b-row>

        <!-- Bonus Cash list table -->
        <b-modal
          ref="choose-offer"
          hide-footer
          title="Select Offer"
          centered
          cancel-variant="outline-secondary"
          size="xl"
        >
          <b-row class="text-modal">
            <b-col cols="12">
              <BonusCashTable
                :status-filter="statusFilter"
                :field-filter="fieldFilter"
                :order-filter="orderFilter"
                :select-type="selectType"
                @hide-modal="hideModalSelectOffer"
              />

            </b-col>
          </b-row>
        </b-modal>

        <!-- Bonus Cash available  -->
        <div
          class="mt-2 mb-2"
          style="font-size: 16px; font-weight: bold"
        >
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          Bonus Cash Available
        </div>
        <b-table
          ref="refBonusCashAvailableTable"
          :items="fetchBonusCashAvailableList"
          :fields="tableAvailableColumns"
          responsive
          show-empty
          primary-key="campaign"
        >
          <template #empty>
            <div
              v-if="loadingGetBonusCashAvailable"
              class="text-center text-dark my-2"
            >
              <p>Loading... Please wait</p>
            </div>

            <div
              v-else-if="!loadingGetBonusCashAvailable && fetchBonusCashAvailableList.length === 0"
              class="text-center text-dark my-2"
            >
              No matching records found
            </div>
          </template>

          <!-- Column: id -->
          <template #cell(id)="{ item }">
            <div class="d-flex align-items-center font-weight-bold">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ ( item.bonusOfferId) }}
              </div>
            </div>
          </template>
          <!-- Column: type -->
          <template #cell(type)="{ item }">
            <div class="d-flex align-items-center font-weight-bold">
              <b-badge
                pill
                :variant="formatColorType(item.type) "
              >
                {{ ( item.type) }}
              </b-badge>
            </div>
          </template>
          <!-- Column: bonus percent -->
          <template #cell(bonusPercent)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ ( item.data.percent) }} {{ item.data.percent ? '%' : '' }}
              </div>
            </div>
          </template>

          <!-- Column: bonus limit -->
          <template #cell(bonusLimit)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ formatCurrencyBuiltInFunc(item.data.bonusLimit) }}
              </div>
            </div>
          </template>

          <!-- Column: open at -->
          <template #cell(openDate)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ formatTime(item.openAt, 'HH:mm:ss dd-MM-yyyy') }}
              </div>
            </div>
          </template>

          <!-- Column: expired at -->
          <template #cell(depositExpiredDays)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ item.bonusExpiredDays }}
              </div>
            </div>
          </template>

          <!-- Column: close at -->
          <template #cell(closeDate)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ formatTime(item.closeAt, 'HH:mm:ss dd-MM-yyyy') }}
              </div>
            </div>
          </template>
          <!-- Column: action -->
          <template #cell(action)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <b-dropdown
                variant="link"
                no-caret
                boundary="viewport"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="handleShowEditModal(item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit Offer</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showModalDelete(item)">
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span class="align-middle ml-50">Remove Offer</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>

        </b-table>

        <!-- Bonus Cash History  -->
        <div
          class="mt-2 mb-2"
          style="font-size: 16px; font-weight: bold"
        >
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          Bonus Cash History
        </div>
        <b-table
          ref="refBonusCashTable"
          :items="fetchBonusCashList"
          :fields="tableColumns"
          responsive
          show-empty
          primary-key="campaign"
        >
          <template #empty>
            <div
              v-if="loadingList"
              class="text-center text-dark my-2"
            >
              <p>Loading... Please wait</p>
            </div>

            <div
              v-else-if="!loadingList && fetchBonusCashList.length === 0"
              class="text-center text-dark my-2"
            >
              No matching records found
            </div>
          </template>

          <!-- Column: issuedDate -->
          <template #cell(issuedDate)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ formatTime(item.createdTime, 'HH:mm:ss dd-MM-yyyy') }}
              </div>
            </div>
          </template>

          <!-- Column: updatedTime -->
          <template #cell(updatedTime)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ formatTime(item.updatedTime, 'HH:mm:ss dd-MM-yyyy') }}
              </div>
            </div>
          </template>

          <!-- Column: expireDate -->
          <template #cell(expireDate)="{ item }">
            <div class="d-flex align-items-center font-weight-bold id-column">
              <div class="d-flex align-items-center font-weight-extrabold">
                {{ formatTime(item.expiredAt, 'HH:mm:ss dd-MM-yyyy') }}
              </div>
            </div>
          </template>
        </b-table>

        <!-- modal delete offer -->
        <b-modal
          ref="delete-offer"
          title="Delete Offer"
          centered
          cancel-variant="outline-secondary"
          @ok="handleSubmitDelete"
        >
          <div style="color: black">
            Are you sure you want to delete this offer?
          </div>
          <template #modal-ok>
            <span>Submit</span>
          </template>
        </b-modal>
      </b-container>
    </validation-observer>
  </div>
</template>

<script>
import { objectProp } from '@/helpers/props'
import {
  formatDateTimeTracking,
  formatDate,
  formatDateLocal,
  formatISO,
  formatCurrencyBuiltInFunc,
  formatDateTimeLocal,
  formatCardNumber,
  formatTime,
  formatDateSend,
} from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import {
  email, required, name, phone, dateOfBirth,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { addDays, formatDistanceStrict, formatISO as formatISOLib } from 'date-fns'
import {
  BButton, BCol, BRow, BContainer, VBModal, BTable, BDropdown, BDropdownItem, BFormInput,
  BBadge,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { UserAction } from '@core/utils/enum/user'
import { ValidationObserver } from 'vee-validate'
import { calculateDate18YearsAgo } from '@core/utils/enum/date'
import { ref } from '@vue/composition-api'
import Spinner from '@core/components/Spinner'
import AddOfferForUser from './UsersAddBonusOffer.vue'
import BonusCashTable from './BonusCashList.vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    ValidationObserver,
    BTable,
    // vSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    Spinner,
    AddOfferForUser,
    BBadge,
    BonusCashTable,
  },

  directives: {
    'b-modal': VBModal,
  },
  UserDetailes: {
    Ripple,
  },
  props: {
    user: objectProp(),
  },
  data() {
    return {
      userId: this.user?.userId.toString() || null,
      username: this.user?.username || null,
      firstName: this.user?.firstName || null,
      middleName: this.user?.middleName || null,
      lastName: this.user?.lastName || null,
      tableColumns: [
        { key: 'bonusOfferId', label: 'Bonus Offer id.' },
        { key: 'issuedDate', label: 'Created date' },
        { key: 'updatedTime', label: 'Updated date' },
        { key: 'expireDate', label: 'Expiry date' },
        // { key: 'bonusType', label: 'Bonus offer type' },
        { key: 'bonus' },
        { key: 'remain' },
        // { key: 'takenDate', label: 'Date Taken Up' },
      ],
      loadingList: false,
      listBonusOffer: [],
      listBonusDeduct: [],
      listBonusHistory: [],
      listBonusAvailable: [],
      offerSelected: null,
      offerSelectedDeduct: null,
      bonusCash: null,
      bonusCashExpireDirect: null,
      bonusDeduct: null,

      errorBonusAmount: '',
      errorBonusOffer: '',
      errorBonusDeductAmount: '',

      isShowModal: false,
      bonusCashOfUser: null,

      tableAvailableColumns: [
        { key: 'id' },
        { key: 'type' },
        { key: 'name' },
        { key: 'description' },
        { key: 'bonusPercent', label: 'Bonus percent' },
        { key: 'bonusLimit', label: 'Bonus limit' },
        { key: 'openDate', label: 'open at' },
        { key: 'depositExpiredDays', label: 'Bonus Expired day' },
        { key: 'closeDate', label: 'close at' },
        { key: 'action' },
      ],
      offerEdit: null,

      fieldFilter: { label: 'Create time', value: 'createdTime' },
      orderFilter: { label: 'DESC', value: 'DESC' },
      statusFilter: { label: 'Active', value: 1 },

      selectType: null,

      defaultOfferDirect: null,
      offerDeleteSelected: null,

      listOfferDefault: [
        {
          id: 1,
          name: 'Deposit $100 and get $100 back in Bonus Cash',
          percent: 100,
          limit: 100,
        },
        {
          id: 2,
          name: 'Deposit $100 and get $50 back in Bonus Cash',
          percent: 50,
          limit: 50,
        },
        {
          id: 3,
          name: 'Deposit $500 and get $100 back in Bonus Cash',
          percent: 20,
          limit: 100,
        },
        {
          id: 4,
          name: 'Deposit $500 and get $125 back in Bonus Cash',
          percent: 25,
          limit: 125,
        },
      ],
      selectedOfferDeposit: null,
    }
  },

  computed: {
    ...mapState('account/bonusCash', ['loading', 'error', 'bonusCashForUser', 'listBonusOfferByUser', 'loadingGetBonusCashAvailable', 'selectedOfferDirect']),
    ...mapState('races/bonusCash', ['loadingCreateBonus']),
    ...mapState({
      loadDirectOffer: s => s.races.bonusCash.createOfferDirect,
    }),
    fullName() {
      if (!this.middleName) return `${this.firstName} ${this.lastName}`
      return `${this.firstName} ${this.middleName} ${this.lastName}`
    },
  },
  watch: {
    bonusCash(val) {
      if (val) this.errorBonusAmount = ''
    },
    offerSelected(val) {
      if (val) this.errorBonusOffer = ''
    },
    loading() {
      this.refBonusCashTable.refresh()
    },
    listBonusHistory() {
      this.convertListBonusDeduct()
    },
    listBonusOffer() {
      this.convertListBonusDeduct()
    },
    bonusDeduct(val) {
      if (val) this.errorBonusDeductAmount = ''
    },
    loadingCreateBonus() {
      this.refBonusCashAvailableTable.refresh()
    },
    isShowModal(val) {
      if (!val) this.offerEdit = {}
    },
    selectedOfferDirect(val) {
      this.defaultOfferDirect = val
    },
    loadDirectOffer() {
      this.refBonusCashTable.refresh()
    },
  },
  async mounted() {
    const res = await this.fetchBonusOffers()
    this.defaultOfferDirect = res[0]
    this.bonusCashOfUser = await this.getBonusCash(this.$router.currentRoute.params.id)
  },
  methods: {
    ...mapActions({
      getListBonus: 'races/bonusCash/getListBonus',
      setBonusForUser: 'account/bonusCash/setBonusCash',
      getBonusCash: 'account/bonusCash/getBonusCash',
      getBonusCashHistory: 'account/bonusCash/getBonusCashHistory',
      getBonusOfferUser: 'account/bonusCash/getBonusOfferUser',
      addProfileUser: 'races/bonusCash/addProfileUser',
      setSelectOfferDeposit: 'account/bonusCash/setSelectOfferDeposit',
      createOfferDirect: 'races/bonusCash/createOfferDirect',
      deleteOffer: 'races/bonusCash/deleteOffer',
      removeProfileUser: 'races/bonusCash/removeProfileUser',
      createBonus: 'races/bonusCash/createBonus',
    }),
    async fetchBonusOffers() {
      const data = await this.getListBonus({
        'sort[field]': 'createdTime',
        'sort[order]': 'DESC',
        isActive: 1,
        page: 1,
        limit: 100,
      })

      if (!data) return this.showToastError(false, 'Error fetching offer list')
      const list = data.data.map(i => ({
        id: i.bonusOfferId,
        label: i.description,
      }))
      this.listBonusOffer = list
      return data.data
    },
    async fetchBonusCashList() {
      const data = await this.getBonusCashHistory({
        'sort[field]': 'createdTime',
        'sort[order]': 'DESC',
        userId: this.$router.currentRoute.params.id,
        page: 1,
        limit: 100,
      })

      if (!data) return this.showToastError(false, 'Error fetching offer list')
      this.listBonusHistory = data
      return data
    },
    async fetchBonusCashAvailableList() {
      const data = await this.getBonusOfferUser({
        page: 1,
        limit: 100,
        userId: this.$router.currentRoute.params.id,
      })

      if (!data) return this.showToastError(false, 'Error fetching offer list')
      this.listBonusAvailable = data
      return data
    },
    formatPrice(value) {
      if (value < 0) return null
      return value
    },
    formatPriceDeduct(value) {
      if (value < 0) return null
      if (value > this.offerSelectedDeduct.remain) return this.offerSelectedDeduct.remain
      return value
    },
    handleSelectBonus(item) {
      this.offerSelected = item
    },
    handleSelectBonusDeduct(item) {
      this.offerSelectedDeduct = item
    },
    async handleApplyBonusForUser() {
      const day = addDays(new Date(), Number(this.bonusCashExpireDirect))
      // eslint-disable-next-line no-shadow
      const formatDate = this.formatDateSend(day)

      if (this.defaultOfferDirect.type === 'deposit') {
        const list = {
          bonusOfferId: this.defaultOfferDirect.bonusOfferId,
          data: {
            userId: this.$router.currentRoute.params.id,
            amount: Number(this.bonusCash),
            expiredAt: formatDate,
          },
        }
        const res = await this.setBonusForUser(list)
        if (res) {
          this.showToastSuccess('Success', 'Add bonus cash successfully!')
          this.bonusCashOfUser = await this.getBonusCash(this.$router.currentRoute.params.id)
          this.bonusCash = null
          this.bonusCashExpireDirect = null
        }
      } else {
        const res = await this.createOfferDirect({
          bonusOfferId: this.defaultOfferDirect.bonusOfferId,
          amount: Number(this.bonusCash),
          expiredAt: formatDate,
          data: [this.$router.currentRoute.params.id],
        })
        if (res) {
          this.showToastSuccess('Success', 'Add bonus cash successfully!')
          this.bonusCashOfUser = await this.getBonusCash(this.$router.currentRoute.params.id)
          this.bonusCash = null
          this.bonusCashExpireDirect = null
        }
      }
    },
    async handleApplyDeduct() {
      if (!this.bonusDeduct) this.errorBonusDeductAmount = 'Bonus Cash Deduct is required'
      if (this.errorBonusDeductAmount) return
      const list = {
        bonusOfferId: this.offerSelectedDeduct.id,
        data: {
          userId: this.$router.currentRoute.params.id,
          amount: -Number(this.bonusDeduct),
        },
      }
      const res = await this.setBonusForUser(list)
      if (res) {
        this.showToastSuccess('Success', 'Deduct bonus cash successfully!')
        this.bonusCashOfUser = await this.getBonusCash(this.$router.currentRoute.params.id)
        this.bonusDeduct = null
        this.offerSelectedDeduct = null
      }
    },
    async convertListBonusDeduct() {
      const list = this.listBonusHistory.map(i => ({
        id: i.bonusOfferId,
        remain: i.remain,
        expiredAt: i.expiredAt,
        deletedTime: i.bonusOffer.deletedTime,
      }))
      const newList = list.filter(i => i.remain > 0 && new Date() <= new Date(i.expiredAt) && i.deletedTime === null)
      this.listBonusDeduct = newList
    },
    handleAddNewOffer() {
      this.isShowModal = true
    },
    resetShowModalCreateOffer() {
      this.isShowModal = false
    },
    formatColorType(val) {
      if (val === 'deposit') return 'light-success'
      return 'light-danger'
    },
    handleShowEditModal(item) {
      this.isShowModal = true
      this.offerEdit = item
    },
    handleShowListOffer() {
      this.selectType = 'deposit'

      // this.$refs['choose-offer'].show()
    },
    hideModalSelectOffer() {
      this.$refs['choose-offer'].hide()
    },
    async handleApplyOfferForUser() {
      const data = {
        name: this.selectedOfferDeposit.name,
        description: this.selectedOfferDeposit.name,
        type: 'deposit',
        openAt: this.formatDateSend(new Date()),
        closeAt: this.formatDateSend(addDays(new Date(), 8)),
        bonusExpiredDays: 8,
        data: {
          percent: this.selectedOfferDeposit.percent,
          bonusLimit: this.selectedOfferDeposit.limit,
        },
        applies: [
          {
            appliableType: 'UserId',
            appliableId: this.$router.currentRoute.params.id,
          },
        ],
      }

      const res = await this.createBonus(data)
      if (res.data) {
        this.showToastSuccess('Success', 'Apply bonus offer successfully!')
        this.setSelectOfferDeposit(null)
      }
    },
    handleShowListOfferDirect() {
      this.selectType = 'direct'
      this.$refs['choose-offer'].show()
    },
    showModalDelete(item) {
      this.$refs['delete-offer'].show()
      this.offerDeleteSelected = item
    },
    async handleSubmitDelete() {
      const res = await this.removeProfileUser({
        bonusOfferId: this.offerDeleteSelected.bonusOfferId,
        data: [
          {
            appliableType: 'UserId',
            appliableId: this.$router.currentRoute.params.id,
          },
        ],
      })
      if (res) {
        this.showToastSuccess('Success', 'Remove user from this offer successfully!')
        this.$nextTick(() => {
          this.$refs['delete-offer'].hide()
        })
        this.offerDeleteSelected = null
      } else {
        this.showToastError(false, 'Error delete this offer')
      }
    },
    handleSelectOfferDepositDefault(item) {
      this.selectedOfferDeposit = item
    },
  },
  setup() {
    const refBonusCashTable = ref(null)

    const refBonusCashAvailableTable = ref(null)

    const { showToastSuccess, showToastError } = useToastHandler()
    return {
      showToastSuccess,
      formatDateTimeTracking,
      formatDateLocal,
      formatDate,
      UserAction,
      showToastError,
      formatISO,
      required,
      email,
      name,
      phone,
      dateOfBirth,
      formatCurrencyBuiltInFunc,
      calculateDate18YearsAgo,
      formatDateTimeLocal,
      formatDistanceStrict,
      formatCardNumber,
      formatISOLib,

      refBonusCashTable,
      refBonusCashAvailableTable,
      formatTime,
      formatDateSend,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header {
  gap: 10px 30px;
}
.col-deposit {
  padding: unset !important;
  margin-bottom: 6px;
  // padding-bottom: 2px;
}
.text-black {
  color: #000;
}

.general-col {
  font-weight: 700;
  font-size: 18px;

  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
}
</style>
