import { computed, ref, watch } from '@vue/composition-api'
// Notification
import { debounce } from 'debounce'

export default function useReBuyTokenList() {
  const refReBuyTokenListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'couponId', label: 'Coupon ID' },
    { key: 'couponCode' },
    { key: 'expiredTime' },
    { key: 'used' },
    { key: 'createdTime' },
    { key: 'createdBy' },
  ]

  const statusOptions = ['All', 'Used', 'Not Use']
  const expireOptions = ['All', 'Expired', 'Not Expire']

  const perPage = ref(10)
  const totalReBuyToken = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)

  const isSortDirDesc = ref(false)
  const sortBy = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refReBuyTokenListTable.value ? refReBuyTokenListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReBuyToken.value,
    }
  })

  const refetchData = () => {
    refReBuyTokenListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalReBuyToken.value = 0
      refetchData()
    }, 700),
  )

  return {
    tableColumns,
    perPage,
    currentPage,
    totalReBuyToken,
    dataMeta,
    perPageOptions,
    searchQuery,
    refReBuyTokenListTable,
    isSortDirDesc,
    sortBy,

    statusOptions,
    expireOptions,
  }
}
