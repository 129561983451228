<template>
  <div>
    <validation-observer ref="generalUserForm">
      <b-container class="bv-example-row">
        <!-- USER DETAIL -->
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-button
              variant="danger"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              style="border-radius: 18px;"
              @click="checkBetstop"
            >
              <Spinner
                v-if="loadingCheckBetstop"
                class="ml-25 align-middle"
                style="width: 115px;"
              />
              <template v-else>
                <span> BETSTOP CHECK</span>
              </template>
            </b-button>

            <b-modal
              ref="modal-betstop"
              title="Betstop"
              centered
              cancel-variant="outline-secondary"
              hide-footer
            >
              <b-card-text
                class="text-black mt-2"
                style="font-size: 20px; font-weight: bold; text-align: center;"
              >
                {{ titleBetstop }}
              </b-card-text>
              <b-button
                class="mt-3"
                block
                @click="$refs['modal-betstop'].hide()"
              >
                Close
              </b-button>
              <!-- <b-button
                v-else
                class="mt-3"
                block
                @click="handleBlockTheAcc"
              >
                <Spinner
                  v-if="loadingClientProfile"
                  class="ml-25 align-middle"
                  style="width: 100%"
                />
                <template v-else>
                  Close
                </template>

              </b-button> -->
            </b-modal>
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <div class="d-flex justify-content-end general-col">
              <p>FullName: {{ fullName }}</p>
            </div>
            <div class="d-flex justify-content-end general-col">
              <p>Status: <b-badge
                pill
                :variant="formatColor(user.isBanned)"
                style="margin-left: 10px;"
              >
                {{ status }}
              </b-badge></p>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <div class="d-flex justify-content-between general-col">
              <span>Current Balance</span><span>{{ (user && formatCurrencyBuiltInFunc(user.balance)) || 0 }}</span>
            </div>
            <div
              v-if="user.userId === ADMIN_UPCOZ"
              class="d-flex justify-content-between general-col"
            >
              <span>UPCOZ Wallet</span><span>{{ (user && formatCurrencyBuiltInFunc(user.upcozBalance)) || 0 }}</span>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>Total Deposit</span><span>{{ user && formatCurrencyBuiltInFunc(user.depositAmount) }}</span>
            </div>

            <div class="d-flex justify-content-between general-col">
              <span>Total Withdrawals</span><span>{{ user && formatCurrencyBuiltInFunc(user.withdrawalAmount) }}</span>
            </div>

            <div class="d-flex justify-content-between general-col">
              <span>Total Showdowns Played</span><span>{{ user.totalCompetitions }}</span>
            </div>

            <div class="d-flex justify-content-between general-col">
              <span>Total Showdowns Won</span><span>{{ user.totalCompetitionsWon }}</span>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>Total Withdrawable</span><span>{{ user && formatCurrencyBuiltInFunc(user.maximumWithdrawalValue) }}</span>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>Total Rebuys Played</span><span>{{ user.totalRebuysPlayed || 0 }}</span>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>Last Rebuys Played</span><span>{{ formatDateTimeLocal((userData && userData.lastRebuyTime) || '', 'EEEE d MMMM yyyy') }}</span>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>First Login</span>
              <b-badge
                v-if="userData.firstLoginDevice"
                pill
                :variant="formatColorLogin(userData.firstLoginDevice)"
                style="margin-left: 10px;"
              >
                {{ userData.firstLoginDevice }}
              </b-badge>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>Last Login</span>
              <b-badge
                v-if="userData.lastLoginDevice"
                pill
                :variant="formatColorLogin(userData.lastLoginDevice)"
                style="margin-left: 10px;"
              >
                {{ userData.lastLoginDevice }}
              </b-badge>
            </div>
            <div
              v-if="userData && userData.lastOpenApp"
              class="d-flex justify-content-between general-col"
            >
              <span>Last Open App</span>
              <span>{{ formatDateTimeLocal((userData && userData.lastOpenApp) || '') }}</span>
            </div>
            <div
              v-if="userData && userData.latestTransaction"
              class="d-flex justify-content-between general-col"
            >
              <span>Latest Transaction<br>({{ userData.latestTransaction.transactionId }})</span>
              <span>{{ formatDateTimeLocal((userData && userData.latestTransaction.createdTime) || '') }}</span>
            </div>
          </b-col>
          <!-- <b-col
            cols="0"
            lg="2"
          /> -->
          <b-col
            cols="12"
            lg="6"
          >
            <div class="d-flex justify-content-between general-col">
              <span>Total Prize Winnings</span><span>{{ formatCurrencyBuiltInFunc(user.totalPrizeWinnings) || 0 }}</span>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>Total Available Rebuys Token</span><span>{{ totalToken || 0 }}</span>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>Total Available Bonus Cash</span><span>{{ formatCurrencyBuiltInFunc(bonusCashOfUser | 0) }}</span>
            </div>

            <div class="d-flex justify-content-between general-col">
              <span>Last Showdown Played</span><span>{{ formatDateTimeLocal((userData && userData.lastShowdown) || '', 'EEEE d MMMM yyyy') }}</span>
            </div>
            <div class="d-flex justify-content-between general-col">
              <span>Current Showdowns:</span>
            </div>
            <div
              v-for="(item, ind) in user.statisticCurrentShowdowns"
              :key="ind"
              class="d-flex justify-content-between general-col"
            >
              <div style="margin-left: 20px; display: flex; align-items: center; gap: 10px;">
                <span style="width: 4px; height: 4px; border-radius: 999px; background: black;" />  {{ `Showdown ${item.competitionId} - ${item.amountBetSlip} Betslips` }}
              </div>
            </div>

          </b-col>
        </b-row>

        <b-row class="pl-1 d-flex font-weight-bold flex-row justify-content-end align-items-center header">
          <span>Date Joined : {{ formatDateTimeLocal((userData && userData.createdTime) || '') }}</span>
          <span v-if="userData && userData.lastLoginDate">
            Last Login : {{ formatDateTimeLocal((userData && userData.lastLoginDate) || '') }}
          </span>
          <span class="d-flex align-items-center">
            VERIFIED:
            <b-form-group label-cols="10">
              <b-form-checkbox
                v-model="isVerify"
                class="mr-0 ml-1 mt-100"
                name="is-rtl"
                switch=""
                inline
                :checked="isVerify"
                @change="val => showModal(UserAction.VERIFY, val)"
              />
            </b-form-group>
          </span>
        </b-row>
        <b-row class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            variant="primary mr-1 mb-100"
            style="font-size: 16px; margin-bottom: 10px;"
            :disabled="logoutAllDevicesLoading"
            @click="handleLogoutAllDevices"
          >
            <Spinner
              v-if="logoutAllDevicesLoading"
              class="ml-25 align-middle"
              style="width: 150px;"
            />
            <template v-else>
              <span class="ml-25 align-middle">Logout All Devices</span>
            </template>
          </b-button>
        </b-row>

        <b-row>
          <b-col
            xl="4"
            cols="12"
          >
            <validation-observer ref="validateUserDetail">
              <b-form>
                <!-- ID -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>ID NUMBER</span>
                  </b-col>
                  <b-col cols="8">
                    <b-form-input
                      v-model="userId"
                      type="text"
                      :readonly="true"
                    />
                  </b-col>
                </b-row>
                <!-- USERNAME -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>USERNAME</span>
                  </b-col>
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="username"
                      rules="required"
                    >
                      <b-form-input
                        v-model="username"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- EMAILADDRESS -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>EMAIL ADDRESS</span>
                  </b-col>
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="emailAddress"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- PHONE NUMBER -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>PHONE NUMBER</span>
                  </b-col>
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="phone number"
                      rules="required|phone"
                    >
                      <b-form-input
                        v-model="phoneNumber"
                        type="tel"
                        maxlength="10"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- TITLE -->

                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>TITLE</span>
                  </b-col>
                  <b-col cols="8">
                    <b-form-select
                      v-model="title"
                      :options="titleList"
                    />
                  </b-col>
                </b-row>

                <!-- FIRSTNAME -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>FIRST NAME</span>
                  </b-col>
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="first name"
                      rules="required|name"
                    >
                      <b-form-input
                        v-model="firstName"
                        type="text"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- MIDDLENAME -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>MIDDLE NAME</span>
                  </b-col>
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="middle name"
                      rules="name"
                    >
                      <b-form-input
                        v-model="middleName"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- LASTNAME -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>LAST NAME</span>
                  </b-col>
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="last name"
                      rules="required|name"
                    >
                      <b-form-input
                        v-model="lastName"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <!-- DOB -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>DOB</span>
                  </b-col>
                  <b-col cols="8">
                    <!-- <validation-provider
                      #default="{ errors }"
                      rules="dateOfBirth"
                    > -->
                    <flat-pickr
                      id="dob"
                      v-model="dob"
                      class="form-control"
                      :config="configDate"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider> -->
                  </b-col>
                </b-row>

                <!-- FULL ADDRESS -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>FULL ADDRESS</span>
                  </b-col>
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <vue-google-autocomplete
                        id="map"
                        ref="fullAddress"
                        v-model="fullAddress"
                        class="form-control"
                        placeholder="Full Address"
                        country="aus"
                        :fields="fields"
                        @placechanged="getAddressData"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- CLIENT PROFILE -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>CLIENT PROFILE</span>
                  </b-col>
                  <b-col cols="8">
                    <b-form-select
                      v-model="profile"
                      :options="clientProfileList"
                    />
                  </b-col>
                </b-row>

                <!-- COUNTRY -->
                <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>COUNTRY</span>
                  </b-col>
                  <b-col cols="8">
                    <b-form-input
                      v-model="country"
                      type="text"
                      readonly
                    />
                  </b-col>
                </b-row>

                <!-- COntact -->
                <!-- <b-row class="mb-1 d-flex align-items-center">
                  <b-col
                    cols="4"
                    class="font-weight-bold"
                  >
                    <span>CONTACT ID</span>
                  </b-col>
                  <b-col cols="8">
                    <validation-provider
                      #default="{ errors }"
                      name="contact id"
                      rules="required"
                    >
                      <b-form-input
                        v-model="contactId"
                        type="text"
                        :formatter="formatNumberOnly"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <b-button
                      variant="primary"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      class="btn-set"
                      @click="showModal(UserAction.UPDATE_NOTES)"
                    >
                      Change
                    </b-button>
                  </b-col>
                </b-row> -->
              </b-form></validation-observer>
          </b-col>
          <b-col
            cols="12"
            xl="8"
            class="md:pl-4"
          >
            <template>
              <!-- Closed Date -->
              <b-row
                v-if="user.selfExcluded"
                class="mb-1 d-flex align-items-center"
              >
                <b-col
                  cols="3"
                  class="font-weight-bold"
                >
                  <span>Closed Date</span>
                </b-col>
                <b-col cols="4">
                  <b-form-input
                    v-model="closedDate"
                    type="text"
                    :readonly="true"
                  />
                </b-col>
              </b-row>

              <!-- Take a break -->
              <b-row
                v-if="timeout"
                class="mb-1 d-flex align-items-center"
              >
                <b-col
                  cols="3"
                  class="font-weight-bold"
                >
                  <span>Timeout</span>
                </b-col>
                <b-col cols="4">
                  <b-form-input
                    v-model="timeout"
                    type="text"
                    :readonly="true"
                  />
                </b-col>
              </b-row>
              <!-- Deposit Limit-->
              <div v-if="user.depositLimits.length > 0">
                Deposit Limit
              </div>
              <b-row
                v-if="formatDeposit('1day')"
                class="mb-1 d-flex align-items-center"
              >
                <b-col
                  cols="3"
                  class="font-weight-bold"
                >
                  <span>1 day</span>
                </b-col>
                <b-col cols="4">
                  <b-form-input
                    :value="formatDeposit('1day')"
                    type="text"
                    :readonly="true"
                  />
                </b-col>
                <b-col
                  cols="4"
                  class="p-0"
                >
                  <b-form-input
                    :value="formatDateTimeLocal(deposit1day)"
                    type="text"
                    :readonly="true"
                  />
                </b-col>
              </b-row>

              <b-row
                v-if="formatDeposit('7day')"
                class="mb-1 d-flex align-items-center"
              >
                <b-col
                  cols="3"
                  class="font-weight-bold"
                >
                  <span>7 days</span>
                </b-col>
                <b-col cols="4">
                  <b-form-input
                    :value="formatDeposit('7day')"
                    type="text"
                    :readonly="true"
                  />
                </b-col>
                <b-col
                  cols="4"
                  class="p-0"
                >
                  <b-form-input
                    :value="formatDateTimeLocal(deposit7day)"
                    type="text"
                    :readonly="true"
                  />
                </b-col>
              </b-row>

              <!--  Withdrawable-->
              <b-row class="mb-1 d-flex align-items-center">
                <b-col
                  cols="3"
                  class="font-weight-bold"
                >
                  <span>Update Withdrawable</span>
                </b-col>
                <b-col cols="3">
                  <div>Add</div>
                  <b-form-input
                    id="deposit-value"
                    v-model="amountWithdrawable"
                    :disabled="!!amountDeductWithdrawable"
                    :no-wheel="true"
                    :formatter="formatNumber"
                  />
                </b-col>
                <b-col cols="3">
                  <div>Deduct</div>
                  <b-form-input
                    id="deposit-value"
                    v-model="amountDeductWithdrawable"
                    :disabled="!!amountWithdrawable"
                    :no-wheel="true"
                    :formatter="formatNumber"
                  />
                </b-col>

                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="loadingAddBalance"
                    class="btn-set mt-2"
                    @click="showModal(UserAction.UPDATE_WITHDRAWABLE)"
                  >
                    <Spinner v-if="loadingAddBalance" />
                    <template v-else>
                      Apply
                    </template>
                  </b-button>
                </b-col>
              </b-row>

              <!-- BALLANCE -->
              <b-row>
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Update Balance
                  </div>
                </b-col>

                <b-col cols="3">
                  <div>Add</div>
                  <b-form-input
                    id="deposit-value"
                    v-model="amount"
                    :disabled="!!amountDeduct"
                    :no-wheel="true"
                    :formatter="formatNumber"
                  />
                </b-col>
                <b-col cols="3">
                  <div>Deduct</div>
                  <b-form-input
                    id="deposit-value"
                    v-model="amountDeduct"
                    :disabled="!!amount"
                    :no-wheel="true"
                    :formatter="formatNumber"
                  />
                </b-col>

                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="loadingAddBalance"
                    class="btn-set mt-2"
                    @click="showModal(UserAction.ADD_BALANCE)"
                  >
                    <Spinner v-if="loadingAddBalance" />
                    <template v-else>
                      Apply
                    </template>
                  </b-button>
                </b-col>
              </b-row>

              <!--UPCOZ BALLANCE -->
              <b-row v-if="user.userId !== ADMIN_UPCOZ">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Transfer to Admin UPCOZ Wallet:
                  </div>
                </b-col>

                <b-col cols="3">
                  <b-form-input
                    id="deposit-value"
                    v-model="upcozAmount"
                    :no-wheel="true"
                    :formatter="formatNumber"
                    class="mt-1"
                  />
                </b-col>

                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="loadingTransferUPCOZ"
                    class="btn-set mt-1"
                    @click="showModal(UserAction.ADD_UPCBALANCE)"
                  >
                    <template>
                      Apply
                    </template>
                  </b-button>
                </b-col>
              </b-row>

              <!--UPCOZ BALLANCE WITHDRAW -->
              <b-row v-if="user.userId === ADMIN_UPCOZ">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Transfer to Admin UPCOZ Wallet:
                  </div>
                </b-col>

                <b-col cols="3">
                  <b-form-input
                    id="deposit-value"
                    v-model="upcozAmount"
                    :no-wheel="true"
                    :formatter="formatNumber"
                    class="mt-1"
                  />
                </b-col>

                <b-col cols="6">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="loadingTransferUPCOZ"
                    class="mt-1"
                    @click="showModal(UserAction.WITHDRAW_UPCBALANCE)"
                  >
                    <template>
                      Withdraw
                    </template>
                  </b-button>
                </b-col>
              </b-row>

              <!-- Re buy token -->
              <b-row class="mt-2">
                <b-col
                  cols="3"
                  class="d-flex flex-column align-items-start justify-content-center"
                >
                  <div class="font-weight-bold">
                    Add Re-Buy Token
                  </div>
                </b-col>
                <b-col cols="3">
                  <div>Expired Time</div>
                  <flat-pickr
                    id="openDate"
                    v-model="selectedExpiredTimeReBuy"
                    class="form-control"
                    :config="config"
                  />
                </b-col>

                <b-col cols="3">
                  <div>Add</div>
                  <b-form-input
                    id="deposit-value"
                    v-model="amountToken"
                    placeholder="Amount"
                    type="number"
                    :no-wheel="true"
                  />
                </b-col>
                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="loadingAddCoupon"
                    class="btn-set mt-2"
                    @click="showModal(UserAction.ADD_RE_BUY_TOKEN)"
                  >

                    Apply
                  </b-button>
                </b-col>
              </b-row>

              <!-- Re vouchers -->
              <b-row class="mt-2">
                <b-col
                  cols="3"
                  class="d-flex flex-column align-items-start justify-content-center"
                >
                  <div class="font-weight-bold">
                    Add Voucher
                  </div>
                </b-col>
                <b-col cols="3">
                  <div>Expired Time</div>
                  <flat-pickr
                    id="openDate"
                    v-model="selectedExpiredTimeVoucher"
                    class="form-control"
                    :config="config"
                  />
                </b-col>

                <b-col cols="3">
                  <div>Add</div>
                  <b-form-input
                    id="deposit-value"
                    v-model="amountVoucher"
                    placeholder="Amount"
                    type="number"
                    :no-wheel="true"
                  />
                </b-col>
                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="loadingAddCoupon"
                    class="btn-set mt-2"
                    @click="showModal(UserAction.ADD_VOUCHER)"
                  >
                    Apply
                  </b-button>
                </b-col>
              </b-row>

              <!-- Affiliate -->
              <b-row class="mt-1">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Affiliate
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <b-form-input v-model="affiliate" />
                </b-col>

                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    class="btn-set"
                    @click="showModal(UserAction.UPDATE_AFFILIATE)"
                  >
                    Apply
                  </b-button>
                </b-col>
              </b-row>

              <!-- Sub Affiliate -->
              <b-row class="mt-1">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Sub Affiliate
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <b-form-input v-model="subAffiliate" />
                </b-col>

                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    class="btn-set"
                    @click="showModal(UserAction.UPDATE_SUB_AFFILIATE)"
                  >
                    Apply
                  </b-button>
                </b-col>
              </b-row>

              <!-- CONTACT ID -->
              <b-row class="mt-1">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Contact ID
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <b-form-input
                    v-model="contactId"
                    type="text"
                    :formatter="formatNumberOnly"
                  />
                </b-col>

                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    class="btn-set"
                    @click="showModal(UserAction.UPDATE_CONTACT_ID)"
                  >
                    Apply
                  </b-button>
                </b-col>
              </b-row>

              <!-- List Credit Card -->
              <b-row
                v-for="(item, index) in listCreditCards"
                :key="index"
                class="mt-1"
              >
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Credit Card {{ index + 1 }}
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <b-form-input
                    :value="formatCardNumber(item.card_number)"
                    readonly
                  />
                </b-col>

                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <b-form-input
                    v-model="item.card_expiry"
                    readonly
                  />
                </b-col>

                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="showModal(UserAction.DELETE_CREDIT_CARD, item.token)"
                  >
                    Delete
                  </b-button>
                </b-col>
              </b-row>

              <!-- BankAccount -->
              <b-row
                v-for="(item, index) in user.bankAccounts"
                :key="index"
                class="mt-1"
              >
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Bank Account
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  class=""
                >
                  <div class="font-weight-bold">
                    BSB
                  </div>
                  <b-form-input
                    :value="item.bsb"
                    readonly
                  />
                </b-col>

                <b-col
                  cols="3"
                  class=""
                >
                  <div class="font-weight-bold">
                    Account Number
                  </div>
                  <b-form-input
                    v-model="item.number"
                    readonly
                  />
                </b-col>

                <b-col
                  cols="3"
                  class="d-flex align-items-end"
                >
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="showModal(UserAction.DELETE_BANK_ACCOUNT, item.bankAccountId)"
                  >
                    Delete
                  </b-button>
                </b-col>
              </b-row>

              <!-- Notes -->
              <b-row class="mt-1">
                <b-col
                  cols="3"
                  class="d-flex align-items-center"
                >
                  <div class="font-weight-bold">
                    Notes
                  </div>
                </b-col>
                <b-col
                  cols="6"
                  class="d-flex align-items-center"
                >
                  <b-form-textarea
                    v-model="notes"
                    rows="3"
                    max-rows="6"
                  />
                </b-col>

                <b-col cols="3">
                  <b-button
                    variant="primary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    class="btn-set"
                    @click="showModal(UserAction.UPDATE_NOTES)"
                  >
                    Apply
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-col>
          <b-col
            cols="12"
            class="mt-4"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="showModal(UserAction.SET_CLIENT_PROFILE)"
            >
              <template> Save changes </template>
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="loading"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </validation-observer>
    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="isLoading"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-black">
        {{ modal.content }}
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isLoading" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

    <!-- WITHDRAW PASSWORD -->
    <b-modal
      id="modal-withdraw"
      ref="modalWithdrawUPCOZ"
      title="Confirm Withdraw UPCOZ Wallet"
      centered
      cancel-variant="outline-secondary"
      @ok="handleWithdrawUPCOZ"
      @hidden="handleHideModal"
    >
      <b-card-text class="text-title">
        <b-form
          style="width: 100%;"
          @submit="onSubmit"
        >
          <b-form-input
            hidden
            type="text"
            autocomplete="username"
          />
          <label
            for="password"
            style="width: 100%;"
          >
            Enter your password

            <b-input-group>
              <b-form-input
                id="password"
                v-model="password"
                :type="passwordConfirm"
                autocomplete="new-password"
                @update="onChange()"
              />

              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconConfirm"
                  class="cursor-pointer"
                  @click="togglePasswordConfirm"
                />
              </b-input-group-append>
            </b-input-group>
            <span
              v-if="isErrorRequired"
              class="text-danger"
            >Password is required</span>
          </label>
        </b-form>
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingTransferUPCOZ" />
        <span v-else>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { objectProp } from '@/helpers/props'
import {
  formatDateTimeTracking,
  formatDate,
  formatDateLocal,
  formatISO,
  formatCurrencyBuiltInFunc,
  formatDateTimeLocal,
  formatCardNumber,
} from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'
import {
  email, required, name, phone, dateOfBirth,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { formatDistanceStrict, formatISO as formatISOLib } from 'date-fns'
import {
  // BAvatar,
  BButton,
  BCol,
  BForm,
  // BFormCheckbox,
  BFormGroup,
  BFormInput,
  // BMedia,
  BRow,
  BFormCheckbox,
  // BInputGroup,
  BInputGroupAppend,
  BContainer,
  VBModal,
  BCardText,
  BFormSelect,
  BFormTextarea,
  BBadge,
  BInputGroup,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { UserAction, ADMIN_UPCOZ } from '@core/utils/enum/user'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { calculateDate18YearsAgo } from '@core/utils/enum/date'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    Spinner,
    ValidationProvider,
    BContainer,
    BFormCheckbox,
    BCardText,
    BFormSelect,
    flatPickr,
    ValidationObserver,
    BForm,
    VueGoogleAutocomplete,
    BFormTextarea,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
  },

  directives: {
    'b-modal': VBModal,
  },
  UserDetailes: {
    Ripple,
  },
  props: {
    user: objectProp(),
  },
  data() {
    return {
      isChangePassword: 'false',
      userDataState: JSON.parse(JSON.stringify(this.user)),
      amount: null,
      amountDeduct: null,
      amountWithdrawable: null,
      amountDeductWithdrawable: null,
      amountToken: null,
      amountVoucher: null,

      listCreditCards: [],
      modal: {
        title: '',
        content: '',
      },
      action: {
        type: '',
        id: '',
      },
      selectedExpiredTimeReBuy: new Date().setDate(new Date().getDate() + 7),
      selectedExpiredTimeVoucher: new Date().setDate(new Date().getDate() + 7),

      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:s',
        enableSeconds: true,
        disable: [{ from: '1970-1-1', to: new Date() }],
      },
      titleList: [
        'Mr', 'Mrs', 'Ms', 'Miss', 'Dr',
      ],

      notes: this.user.notes,

      isVerify: this.user.isVerified,
      // timeout: this.user.breakTime ? `${formatDistanceStrict(new Date(this.user.breakTime), new Date())}` : '',
      closedDate: formatDateTimeLocal(this.user.closedDate),
      userId: this.user?.userId.toString() || null,
      username: this.user?.username || null,
      title: this.user?.title || null,
      firstName: this.user?.firstName || null,
      middleName: this.user?.middleName || null,
      lastName: this.user?.lastName || null,
      dob: formatDate(this.user?.dob, 'yyyy-MM-dd') || null,
      fullAddress: this.user?.fullAddress || null,
      emailAddress: this.user?.email || null,
      phoneNumber: this.user?.phoneNumber?.replace('+61', '0') || null,
      profile: this.user?.profile?.replace('_', ' ') || null,
      country: this.user?.country || null,
      contactId: typeof this.user?.freshsaleContactId === 'number' ? this.user?.freshsaleContactId : null,
      unitNumber: this.user?.unitNumber || '',
      streetNumber: this.user?.streetNumber || '',
      streetName: this.user?.streetName || '',
      streetType: this.user?.streetType || '',
      postCode: this.user?.postCode || '',
      suburb: this.user?.suburb || '',
      state: this.user?.state || '',
      affiliate: this.user?.primaryAffiliate || '',
      subAffiliate: this.user?.subAffiliate || '',
      fields: [
        'address_components',
        'adr_address',
        'alt_id',
        'formatted_address',
        'geometry',
        'id',
        'name',
        'scope',
        'type',
        'vicinity',
      ],
      isSelectedAddress: false,
      configDate: {
        // dateFormat: 'd-m-Y',
        altFormat: 'd-m-Y',
        altInput: true,

        static: true,
        maxDate: this.calculateDate18YearsAgo(),
      },
      deposit1day: null,
      deposit7day: null,
      tokenDeleteCard: null,
      bankAccountId: null,

      bonusCashOfUser: null,

      titleBetstop: '',
      upcozAmount: null,
      password: null,
      passwordConfirm: 'password',
      isErrorRequired: false,
    }
  },

  computed: {
    ...mapState({
      verifyAccount: s => s.user.verify,
    }),
    ...mapState('user/update', ['error', 'loading']),
    ...mapState('user/listCard', ['loadingDeleteCard', 'loadingDeleteBankAccount']),
    ...mapState('user/detail', [
      'loading',
      'loadingAddBalance',
      'userData',
      'loadingClientProfile',
      'loadingAddCoupon',
      'totalToken',
      'loadingWithdrawable',
      'totalVouchers',
      'loadingCheckBetstop',
      'loadingTransferUPCOZ',
    ]),

    ...mapState('user/autoFollow', { autoFollowLoading: 'loading' }),
    ...mapState('user/logoutAllDevices', { logoutAllDevicesLoading: 'loading' }),
    ...mapState('races/setting', ['clientProfileList']),
    passwordToggleIcon() {
      return this.passwordCB === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    passwordToggleIconConfirm() {
      return this.passwordConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    timeout() {
      if (this.user.breakTime) {
        const time = new Date(this.user.breakTime).getTime() - new Date().getTime()
        if (time > 0) {
          return `${formatDistanceStrict(new Date(this.user.breakTime), new Date())}`
        }
        return null
      }
      return null
    },
    status() {
      return `${this.user.isBanned ? 'Disabled' : 'Enabled'}${this.timeout ? '/Timeout' : ''}${
        this.user.selfExcluded ? '/Closed' : ''
      }${this.user.isActive ? '' : '/Deleted'}${this.user.isBlocked ? '/Blocked' : ''}`
    },
    isLoading() {
      return (
        this.loadingAddBalance
        || this.loading
        || this.verifyAccount.loading
        || this.loadingClientProfile
        || this.loadingAddCoupon
        || this.loadingDeleteCard
        || this.loadingWithdrawable
        || this.loadingDeleteBankAccount
        || this.loadingTransferUPCOZ
      )
    },
    fullName() {
      if (!this.middleName) return `${this.firstName} ${this.lastName}`
      return `${this.firstName} ${this.middleName} ${this.lastName}`
    },
  },
  async mounted() {
    this.getProfileType()
    this.handleGetListToken()
    this.handleGetTotalVoucher()
    this.bonusCashOfUser = await this.getBonusCash(this.$router.currentRoute.params.id)
  },

  async created() {
    const cards = await this.handleGetCreditCards(this.user.userId)
    if (cards.length) this.listCreditCards = cards
  },

  methods: {
    ...mapActions({
      updateUserAsync: 'user/update/updateUserAsync',
      autoFollow: 'user/autoFollow/autoFollow',
      updateUserBalance: 'user/detail/updateUserBalance',
      updateUserWithdrawal: 'user/detail/updateUserWithdrawal',
      asyncUser: 'user/detail/asyncUser',
      updateClientProfile: 'user/detail/updateClientProfile',
      handleAddCoupon: 'user/detail/handleAddCoupon',
      getListReBuy: 'user/detail/getListReBuy',
      getListVouchers: 'user/detail/getListVouchers',
      asyncVerifyUser: 'user/verify/verifyUser',
      handleGetCreditCards: 'user/listCard/handleGetCreditCards',
      handleGetDeleteCard: 'user/listCard/handleGetDeleteCard',
      handleGetDeleteBankAccount: 'user/listCard/handleGetDeleteBankAccount',
      setLogoutAllDevices: 'user/logoutAllDevices/setLogoutAllDevices',
      getBonusCash: 'account/bonusCash/getBonusCash',
      handleCheckBetstop: 'user/detail/handleCheckBetstop',
      transferUPCOZWallet: 'user/detail/transferUPCOZWallet',
      withdrawUPCOZWallet: 'user/detail/withdrawUPCOZWallet',
      getProfileType: 'races/setting/getProfileType',
    }),
    formatNumberOnly(value) {
      value = value.replace(/[^0-9]/, '')
      return value
    },
    formatColor(val) {
      if (!val) {
        return 'light-success'
      }
      return 'danger'
    },
    formatColorLogin(val) {
      if (val === 'app') {
        return 'light-success'
      }
      return 'light-danger'
    },

    formatNumber(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },

    resetForm() {
      this.isVerify = this.user.isVerified
      this.userId = this.user?.userId.toString() || null
      this.username = this.user?.username || null
      this.firstName = this.user?.firstName || null
      this.middleName = this.user?.middleName || null
      this.lastName = this.user?.lastName || null
      this.dob = this.user?.dob || null
      this.fullAddress = this.user?.fullAddress || null
      this.emailAddress = this.user?.email || null
      this.phoneNumber = this.user?.phoneNumber?.replace('+61', '0') || null
      this.profile = this.user?.profile?.replace('_', ' ') || null
      this.country = this.user?.country || null
      this.unitNumber = this.user?.unitNumber || ''
      this.streetNumber = this.user?.streetNumber || ''
      this.streetName = this.user?.streetName || ''
      this.streetType = this.user?.streetType || ''
      this.postCode = this.user?.postCode || ''
      this.suburb = this.user?.suburb || ''
      this.state = this.user?.state || ''
      this.contactId = typeof this.user?.freshsaleContactId === 'number' ? this.user?.freshsaleContactId : null
    },

    getAddressData(addressData, place) {
      this.fullAddress = place.formatted_address
      const routeItem = place.address_components.find(item => item.types.includes('route'))

      const streetLongName = routeItem?.long_name.split(' ')
      this.streetType = streetLongName?.pop() || ''
      this.streetName = streetLongName.join(' ') || ''
      const postCodeItem = place.address_components.find(item => item.types.includes('postal_code'))
      this.postCode = postCodeItem?.long_name || ''
      const streetNumberItem = place.address_components.find(item => item.types.includes('street_number'))
      this.streetNumber = streetNumberItem?.long_name || ''
      const subpremiseItem = place.address_components.find(item => item.types.includes('subpremise'))
      this.unitNumber = subpremiseItem?.long_name || ''
      const stateItem = place.address_components.find(item => item.types.includes('administrative_area_level_1'))
      this.state = stateItem?.long_name || ''
      const cityItem = place.address_components.find(item => item.types.includes('locality'))
      this.suburb = cityItem?.long_name || ''
    },
    async verifyUser() {
      const result = await this.asyncVerifyUser({ id: this.user.userId, isVerified: !this.user.isVerified ? 1 : 0 })
      const statusText = !this.user.isVerified ? 'verified' : 'unverified'

      if (!result) return
      await this.asyncUser({ id: this.$router.currentRoute.params.id })
      this.isVerify = this.user.isVerified
      this.showToastSuccess('Success', `User ${statusText} Successfully!`)
    },

    async handleUpdateBalance() {
      const res = await this.updateUserBalance({
        id: this.$router.currentRoute.params.id,
        amount: this.amount || -+this.amountDeduct,
      })
      if (res) {
        await this.asyncUser({ id: this.$router.currentRoute.params.id })
        this.showToastSuccess('Success', `${this.amount ? 'Add' : 'Deduct'} Balance Successfully`)
        this.amount = null
        this.amountDeduct = null
      }
      return res
    },

    async handleUpdateWithdrawable() {
      const res = await this.updateUserWithdrawal({
        id: this.$router.currentRoute.params.id,
        amount: this.amountWithdrawable || -+this.amountDeductWithdrawable,
      })
      if (res) {
        await this.asyncUser({ id: this.$router.currentRoute.params.id })
        this.showToastSuccess('Success', `${this.amountWithdrawable ? 'Add' : 'Deduct'} Withdrawable Successfully`)
        this.amountWithdrawable = null
        this.amountDeductWithdrawable = null
      }
      return res
    },
    async handleUpdateClientProfile() {
      const newData = {
        userId: this.userId,
        username: this.username,
        firstName: this.firstName,
        middleName: this.middleName?.length > 0 ? this.middleName : null,
        lastName: this.lastName,
        dob: this.dob,
        fullAddress: this.fullAddress,
        email: this.emailAddress,
        phoneNumber: `+61${this.phoneNumber?.slice(1)}`,
        profile: this?.profile?.replace(' ', '_') || null,
        country: this.country,
        unitNumber: this.unitNumber,
        streetNumber: this.streetNumber,
        streetName: this.streetName,
        streetType: this.streetType,
        postCode: this.postCode,
        suburb: this.suburb,
        state: this.state,
        title: this.title,
        gender: this.title === 'Mr' ? 'M' : 'F',
        // freshsaleContactId: this.contactId,
      }
      const res = await this.updateClientProfile({ ...newData })
      if (res) {
        this.$refs.modal.hide()
        this.showToastSuccess('Success', 'Update Client Profile Successfully')
        this.asyncUser({ id: this.$router.currentRoute.params.id })
      } else {
        this.resetForm()
      }
    },
    async handleUpdateContactId() {
      const newData = {
        userId: this.userId,
        freshsaleContactId: this.contactId,
      }
      const res = await this.updateClientProfile({ ...newData })
      if (res) {
        this.showToastSuccess('Success', 'Update Contact Id Successfully')
      }
    },
    async handleUpdateNotes() {
      const newData = {
        userId: this.userId,
        notes: this.notes,
      }
      const res = await this.updateClientProfile({ ...newData })
      if (res) {
        this.showToastSuccess('Success', 'Update Notes Successfully')
      } else {
        this.resetForm()
      }
    },
    async handleUpdateAffiliate() {
      const res = await this.updateClientProfile({
        primaryAffiliate: this.affiliate,
        userId: this.userId,
      })
      if (res) {
        this.showToastSuccess('Success', 'Update Affiliate Successfully')
      } else {
        this.resetForm()
      }
    },
    async handleUpdateSubAffiliate() {
      const res = await this.updateClientProfile({
        subAffiliate: this.subAffiliate,
        userId: this.userId,
      })
      if (res) {
        this.showToastSuccess('Success', 'Update Sub Affiliate Successfully')
      } else {
        this.resetForm()
      }
    },
    async handleAddCouponByType(type) {
      const res = await this.handleAddCoupon({
        id: this.$router.currentRoute.params.id,
        amount: type === 'VOUCHER' ? this.amountVoucher : this.amountToken,
        expiredTime: type === 'VOUCHER' ? formatISO(this.selectedExpiredTimeVoucher) : formatISO(this.selectedExpiredTimeReBuy),
        couponType: type,
      })
      if (res) {
        if (type === 'VOUCHER') {
          this.amountVoucher = null
          this.handleGetTotalVoucher()
        }
        if (type === 'REBUY_TOKEN') {
          this.handleGetListToken()
          this.amountToken = null
        }

        this.showToastSuccess('Success', `Add ${type} Successfully`)
      }
      return res
    },

    async handleGetListToken() {
      const res = await this.getListReBuy({
        id: this.$router.currentRoute.params.id,
        shouldExpired: 0,
        couponStatus: 'unused',
        couponType: 'REBUY_TOKEN',
        onlyCount: 1,
      })
      if (!res) {
        this.showToastError('Error', 'Fail To Load List Re-buy Token')
      }
      return res
    },

    async handleGetTotalVoucher() {
      const res = await this.getListVouchers({
        id: this.$router.currentRoute.params.id,
        shouldExpired: 0,
        couponStatus: 'unused',
        couponType: 'VOUCHER',
        onlyCount: 1,
      })
      if (!res) {
        this.showToastError('Error', 'Fail To Load Total Voucher')
      }
      return res
    },

    async handleDeleteBankAccount() {
      const res = await this.handleGetDeleteBankAccount(this.bankAccountId)
      if (res) {
        this.$store.commit('user/detail/DELETE_BANK_ACCOUNT', this.bankAccountId)
        this.showToastSuccess('Success', 'Delete bank account successfully')
      }
      return res
    },
    async handleTranserUPCOZ() {
      const res = await this.transferUPCOZWallet({
        amount: this.upcozAmount,
        fromUser: this.$router.currentRoute.params.id,
        toUser: this.ADMIN_UPCOZ,
        fromWalletType: 'DEFAULT',
        toWalletType: 'UPCOZ',

      })
      if (res) {
        await this.asyncUser({ id: this.$router.currentRoute.params.id })
        this.showToastSuccess('Success', 'Transfer to UPCOZ account Successfully')
        this.upcozAmount = null
      }
      return res
    },
    handleWithdrawModalPassword() {
      this.$refs.modalWithdrawUPCOZ.show()
    },
    async handleWithdrawUPCOZ(event) {
      event.preventDefault()

      if (!this.password) {
        this.isErrorRequired = true
        return
      }

      if (this.user.bankAccounts.length === 0) {
        return this.showToastError('Error', 'Please add bank account!')
      }

      const res = await this.withdrawUPCOZWallet({
        amount: this.upcozAmount,
        password: this.password,
        walletType: 'UPCOZ',
        type: 'bank-account',
        bankAccountId: this.user.bankAccounts[0].bankAccountId,
        userId: this.ADMIN_UPCOZ,
      })
      if (res) {
        this.$refs.modalWithdrawUPCOZ.hide()
        await this.asyncUser({ id: this.$router.currentRoute.params.id })
        this.showToastSuccess('Success', 'Withdraw from UPCOZ wallet Successfully')
        this.upcozAmount = null
      }
      return res
    },
    handleResetForm() {
      this.userDataState = JSON.parse(JSON.stringify(this.user))
      this.userId = this.user?.userId.toString() || null
      this.username = this.user?.username || null
      this.firstName = this.user?.firstName || null
      this.middleName = this.user?.middleName || null
      this.lastName = this.user?.lastName || null
      this.dob = this.user?.dob || null
      this.fullAddress = this.user?.fullAddress || null
      this.emailAddress = this.user?.email || null
      this.phoneNumber = this.user?.phoneNumber?.replace('+61', '0') || null
      this.profile = this.user?.profile || null
      this.country = this.user?.country || null
      this.title = this.user?.title || null
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },
    async showModal(type, val) {
      this.action = { type }
      if (type === UserAction.ADD_BALANCE) {
        if (!this.amount && !this.amountDeduct) return
        if (this.amountDeduct > this.user.balance && !this.amount) {
          this.showToastError('The balance is lower than the deduct amount', 'Error')
          this.amountDeduct = null
          return
        }
        this.setModal(
          `${this.amountDeduct ? 'Deduct' : 'Add'} Balance`,
          `Are you sure you want to ${this.amountDeduct ? 'minus' : 'add'} $${
            this.amount || this.amountDeduct
          } for this user?`,
        )
      }

      if (type === UserAction.UPDATE_WITHDRAWABLE) {
        if (!this.amountWithdrawable && !this.amountDeductWithdrawable) return
        if (this.amountDeductWithdrawable > this.user.maximumWithdrawalValue && !this.amountWithdrawable) {
          this.showToastError('The withdrawable amount is lower than the deduct amount', 'Error')
          this.amountDeductWithdrawable = null
          return
        }
        this.setModal(
          `${this.amountDeductWithdrawable ? 'Deduct' : 'Add'} Withdrawable`,
          `Are you sure you want to ${this.amountDeductWithdrawable ? 'minus' : 'add'} $${
            this.amountWithdrawable || this.amountDeductWithdrawable
          } for this user?`,
        )
      }
      if (type === UserAction.SET_CLIENT_PROFILE) {
        const valid = await this.$refs.validateUserDetail.validate()
        if (!valid) return
        this.setModal('Set Client Profile', 'Are you sure you want to update new profile for this user?')
      }
      if (type === UserAction.UPDATE_NOTES) {
        if (!this.notes) return
        this.setModal('Set Note', 'Are you sure you want to update new note for this user?')
      }
      if (type === UserAction.ADD_RE_BUY_TOKEN) {
        if (this.amountToken <= 0) return
        this.setModal('Add Re-buy Token', `Are you sure you want to  ${this.amountToken} re-buy ${this.amountToken > 1 ? 'tokens' : 'token'} for this user?`)
      }
      if (type === UserAction.ADD_VOUCHER) {
        if (this.amountVoucher <= 0) return
        this.setModal('Add Voucher', `Are you sure you want to  ${this.amountVoucher}  ${this.amountVoucher > 1 ? 'vouchers' : 'voucher'} for this user?`)
      }

      if (type === UserAction.VERIFY) {
        this.isVerify = !val
        this.setModal(
          `${val ? 'Verify' : 'UnVerify'} User`,
          `Are you sure you want to ${val ? 'Verify' : 'UnVerify'} this user?`,
        )
      }

      if (type === UserAction.DELETE_CREDIT_CARD) {
        this.tokenDeleteCard = val
        this.setModal('Delete Card', 'Are you sure you want to delete this card?')
      }

      if (type === UserAction.DELETE_BANK_ACCOUNT) {
        this.bankAccountId = val
        this.setModal('Delete bank account', 'Are you sure you want to delete this bank account?')
      }

      if (type === UserAction.UPDATE_AFFILIATE) {
        if (!this.affiliate) return
        this.setModal('Update Affiliate', 'Are you sure you want to update affiliate of this user?')
      }

      if (type === UserAction.UPDATE_SUB_AFFILIATE) {
        if (!this.subAffiliate) return
        this.setModal('Update Affiliate', 'Are you sure you want to update sub affiliate of this user?')
      }

      if (type === UserAction.UPDATE_CONTACT_ID) {
        if (!this.contactId) return
        this.setModal('Update ContactId', 'Are you sure you want to update contact id of this user?')
      }

      if (type === UserAction.ADD_UPCBALANCE) {
        if (!this.upcozAmount) return
        if (this.upcozAmount > this.user.balance) {
          this.showToastError('The balance is lower than the amount', 'Error')
          this.upcozAmount = null
          return
        }
        this.setModal(
          'Transfer to UPCOZ account',
          `Are you sure you want to transfer $${
            this.upcozAmount
          } for UPCOZ account?`,
        )
      }
      if (type === UserAction.WITHDRAW_UPCBALANCE) {
        if (!this.upcozAmount) return
        if (this.upcozAmount > this.user.upcozBalance) {
          this.showToastError('The upcozBalance is lower than the amount', 'Error')
          this.upcozAmount = null
          return
        }
        this.setModal(
          'Withdraw UPCOZ ballance',
          `Are you sure you want to withdraw $${
            this.upcozAmount
          } from UPCOZ wallet?`,
        )
      }

      this.$refs.modal.show()
    },

    async handleSubmitModal(event) {
      event.preventDefault()
      const { type } = this.action

      if (type === UserAction.ADD_BALANCE) await this.handleUpdateBalance()
      if (type === UserAction.DELETE_CREDIT_CARD) {
        const res = await this.handleGetDeleteCard({
          accountId: this.user.userId,
          token: this.tokenDeleteCard,
        })

        if (res) {
          this.listCreditCards = this.listCreditCards.filter(c => c.token !== this.tokenDeleteCard)
          this.showToastSuccess('Success', 'Delete credit card successfully')
          this.tokenDeleteCard = null
        }
      }

      if (type === UserAction.VERIFY) await this.verifyUser()
      if (type === UserAction.SET_CLIENT_PROFILE) await this.handleUpdateClientProfile()
      if (type === UserAction.ADD_RE_BUY_TOKEN) await this.handleAddCouponByType('REBUY_TOKEN')
      if (type === UserAction.ADD_VOUCHER) await this.handleAddCouponByType('VOUCHER')

      if (type === UserAction.UPDATE_AFFILIATE) await this.handleUpdateAffiliate()
      if (type === UserAction.UPDATE_SUB_AFFILIATE) await this.handleUpdateSubAffiliate()
      if (type === UserAction.UPDATE_WITHDRAWABLE) await this.handleUpdateWithdrawable()
      if (type === UserAction.UPDATE_NOTES) await this.handleUpdateNotes()
      if (type === UserAction.DELETE_BANK_ACCOUNT) await this.handleDeleteBankAccount()
      if (type === UserAction.UPDATE_CONTACT_ID) await this.handleUpdateContactId()
      if (type === UserAction.ADD_UPCBALANCE) await this.handleTranserUPCOZ()
      if (type === UserAction.WITHDRAW_UPCBALANCE) await this.handleWithdrawModalPassword()
      if (!this.isLoading) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },
    formatDeposit(val1) {
      const listDeposit = this.user.depositLimits
      if (!listDeposit?.length) return null
      if (val1 === '1day') {
        const item = listDeposit.find(i => i.period === 1)
        if (item) {
          this.deposit1day = item.endTime
          return item.amount
        }
      }
      if (val1 === '7day') {
        const item = listDeposit.find(i => i.period === 7)
        if (item) {
          this.deposit7day = item.endTime
          return item.amount
        }
      }
    },
    async handleLogoutAllDevices() {
      const res = await this.setLogoutAllDevices(this.$router.currentRoute.params.id)
      if (res) this.showToastSuccess('Success', 'Logout all devices for this user successfully!')
    },
    async checkBetstop() {
      const data = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        dob: this.user.dob.slice(0, 10),
        email: this.user.email,
        postCode: this.user.postCode,
        phoneNumber: `0${this.user.phoneNumber.substring(3)}`,
      }
      const res = await this.handleCheckBetstop(data)
      if (res) {
        this.titleBetstop = 'Betstop : TRUE'
        await this.handleBlockTheAcc()
        this.$refs['modal-betstop'].show()
      } else {
        this.titleBetstop = 'Betstop : FALSE'
        this.$refs['modal-betstop'].show()
      }
    },
    async handleBlockTheAcc() {
      const result = await this.updateClientProfile({ userId: this.user.userId, isBanned: 1 })
      if (result) {
        this.$refs['modal-betstop'].hide()
        this.showToastSuccess('Success', 'User disabled successfully!')
        this.asyncUser({ id: this.$router.currentRoute.params.id })
      }
    },
    handleHideModal() {
      this.password = null
      this.passwordConfirm = 'password'
    },
    onSubmit(event) {
      event.preventDefault()
    },
    onChange() {
      this.isErrorRequired = false
    },
    togglePasswordConfirm() {
      this.passwordConfirm = this.passwordConfirm === 'password' ? 'text' : 'password'
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()
    return {
      showToastSuccess,
      formatDateTimeTracking,
      formatDateLocal,
      formatDate,
      UserAction,
      showToastError,
      formatISO,
      required,
      email,
      name,
      phone,
      dateOfBirth,
      formatCurrencyBuiltInFunc,
      calculateDate18YearsAgo,
      formatDateTimeLocal,
      formatDistanceStrict,
      formatCardNumber,
      formatISOLib,
      ADMIN_UPCOZ,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header {
  gap: 10px 30px;
}

.btn-set {
  width: 86px;
  height: fit-content;
}
.col-deposit {
  padding: unset !important;
  margin-bottom: 6px;
  // padding-bottom: 2px;
}
.text-black {
  color: #000;
}
.deposit-value {
  width: 86px;
}

.dropdown-select {
  max-width: 200px;
}

.dark-layout
  .flatpickr-calendar
  .flatpickr-days
  .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #ccc;
}
.mt-auto {
  margin-top: auto;
}

.general-col {
  font-weight: 700;
  font-size: 18px;

  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
}
</style>
